.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-color: inherit;
}

.auth__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  max-width: 400px;
  gap: 45px;
}

.auth__content-logo {
  width: 100%;
  max-width: 8em;
  height: 8em;
  background-color: azure;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth__logo {
  object-fit: cover;
  max-width: 5em;
}

.auth__form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 321px;
  min-height: 56vh;
}

.auth__form-content {
  display: flex;
  flex-direction: column;
  gap: 45px;
  margin-bottom: 20px;
}

.auth__form-title {
  font-size: 3.2em;
  font-weight: 400;
  line-height: 1;
  margin: 20px 0;
  padding: 0;
  text-align: left;
  color: rgba(17, 38, 60, 1);
}

.auth__form-inputs {
  display: flex;
  flex-direction: column;
  gap: 15px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.auth__form-input-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.auth__form-label {
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1;
  color: rgba(17, 38, 60, 1);
}

.auth__form-input {
  font-size: 1.6em;
  font-weight: 400;
  line-height: 1;
  padding: 16px 18px;
  border: 1px solid rgba(199, 203, 210, 1);
  border-radius: 25px;
  background-color: inherit;
  transition: border-bottom .5s linear;
  color: rgba(130, 143, 157, 1);
  outline: none;
}

.auth__form-input-content_type_hidden {
  visibility: hidden;
}

.auth__form-input-login-err {
  /* visibility: hidden; */
  margin: 0;
  padding: 0;
  color: rgb(255, 2, 2);
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  height: 26px;
}

input::-moz-placeholder {
  color: rgba(17, 38, 60, 1);
}

input::-webkit-input-placeholder {
  color: rgba(17, 38, 60, 1);
}

.auth__form-input:hover {
  border: 1px solid rgba(25, 178, 230, 1);
}

.auth__form-content-button {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.auth__error-text {
  visibility: hidden;
  margin-bottom: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 13.31px;
  text-align: center;
  color: rgba(255, 0, 76, 1);
}

.auth__error-text_type_active {
  visibility: visible;
}

.auth__form-button {
  width: 100%;
  max-width: 353px;
  color: rgba(250, 250, 250, 1);
  background-color: rgba(25, 178, 230, 1);
  min-height: 43px;
  border: none;
  border-radius: 24px;
  font-size: 1.6em;
  line-height: 1;
  font-weight: 400;
  margin: 0;
  padding: 0;
  transition: background-color .2s linear;
}

.auth__form-button_type_disabled {
  background-color: darkgray;
}

.auth__form-button:hover {
  cursor: pointer;
}

.auth__form-text-content {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.auth__about-project {
  margin: 20px 0 0;
  font-size: 1.6em;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
  color: rgba(17, 38, 60, 1);
}

.auth__app-link {
  text-decoration: none;
}

.auth__app-link,
.auth__form-text,
.auth__form-login,
.partner__text {
  font-size: 1.6em;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  padding: 0;
  color: rgba(17, 38, 60, 1);
}

.partner__text {
  font-size: 1.2em;
  line-height: 20px;
}

.auth__app-link,
.auth__about-project,
.auth__form-login {
  font-weight: 500;
  color: rgba(25, 178, 230, 1);
  text-decoration: none;
}

.auth__form-password-error {
  color: #30BA48;
  font-size: 13px;
  font-weight: 500;
  height: 26px;
}

.auth__form-password-error_type_active {
  color: rgb(255, 2, 2);
}

.privacy-link {
  text-align: center;
}

.privacy-text,
.auth__form-paragraph {
  font-size: 16px;
}

.auth__form-partners_container {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.faise {
  width: 100%;
  max-width: 150px;
  margin-bottom: 20px;
}

@media (max-height: 1027px) {
  .auth__form {
    min-height: 70vh;
  }
}

@media (max-height: 820px) {
  .auth__form {
    min-height: 80vh;
  }
}

@media (max-height: 710px) {
  .auth__form {
    min-height: 97.5vh;
  }
}

@media (max-width: 540px) {
  .auth__content {
    max-width: 300px;
    gap: 7.8125vh;
  }

  .auth__logo {
    max-width: 5em;
  }

  .auth__form {
    margin: 0 10px;
    max-width: 300px;
    min-height: 65vh;
  }

  .auth__form-content {
    gap: 3vh;
  }

  .auth__form-inputs {
    gap: 20px;
  }

  .auth__form-button {
    max-width: 300px;
  }
}

@media (max-width: 320px) {
  .auth__content {
    max-width: 240px;
  }

  .auth__logo {
    max-width: 6em;
  }

  .auth__form {
    max-width: 240px;
  }

  .auth__form-button {
    max-width: 240px;
  }
}